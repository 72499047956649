import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet blanc posseix un capell de fins a 9 cm de diàmetre, hemisfèric i posteriorment més pla i enfonsat una mica al centre. El capell pot tornar groc quan el manipulem. Davall el capell trobem les làmines blanc rosades, que passen a rosa i finalment marró xocolata amb l’aresta blanquinosa. El peu curt i corbat porta un anell súper amb el marge bastant marcat. Les espores són marró en massa i quasi rodones, de 5-7 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      